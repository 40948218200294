const configuration = () => ({
    routes: [
        {
            title: 'Aufträge',
            url: '/auftrag/pool',
            active: ['admin']
        },
        {
            title: 'Statistik',
            url: '/statistik/auswertung'
        },
    ],
    BSM_Routes: [
        {
            title: 'Einstellungen',
            url: '/einstellungen'
        },
        {
            title: 'Ehrenamt',
            url: '/ehrenamt'
        }
    ],
    menus: {},
    tables: {},
    forms: {
        anrufer: [
            {
                name: 'anrufer',
                link: 'anrufer'
            }
        ],
        auftrag_erfassen: {
            standard: [
                {
                    name: 'anrufer',
                    label: 'Wer hat angerufen?',
                    type: 'select',
                    link: 'anrufer',
                    required: true
                },
                {
                    name: 'umgebung',
                    label: 'Geschützte Umgebung?',
                    type: 'select',
                    link: 'umgebung',
                    width: 6
                },
                {
                    name: 'personen',
                    label: 'Personen',
                    type: 'number',
                    width: 3
                },
                {
                    name: 'prio',
                    label: 'Priorität',
                    link: 'prio',
                    type: 'select',
                    width: 7,
                    required: true
                },
                {
                    name: 'rolli',
                    label: 'Rollstuhl',
                    type: 'select',
                    link: 'rolli',
                    width: 4,
                    required: true
                },
                {
                    name: 'ort',
                    label: 'Ort',
                    type: 'mapslist',
                    width: 8
                },
                {
                    name: 'pluscode',
                    label: 'Plus Code',
                    width: 4
                },
                {
                    name: 'ansprechpartner',
                    label: 'Rückrufinfos'
                },
                {
                    name: 'beschreibung',
                    label: 'Genauere Beschreibung',
                    type: 'textarea',
                    width: 24
                }
            ]
        },
        statistik: [
            {
                name: 'zeitraum',
                label: 'Zeitraum auswählen',
                type: 'range',
                required: true
            },
            {
                name: 'filter',
                label: 'Filter',
                link: 'tbl_User',
                type: 'checkbox'
            }
        ],
        tagesinfo: [
            {
                name: 'beschreibung',
                label: 'Genauere Beschreibung',
                type: 'textarea',
                width: 24
            }
        ],
        neuesFahrzeug: [
            {
                name: 'name',
                label: 'Name'
            },
            {
                name: 'kennzeichen',
                label: 'Kennzeichen'
            },
            {
                name: 'trackerID',
                label: 'Tracker ID'
            },
            {
                name: 'trackerKennung',
                label: 'IMEI'
            },
        ],
        mitarbeiterProfil: [
            {
                name: 'Vorname',
                label: 'Vorname',
                required: true,
                width: 12
            },
            {
                name: 'Nachname',
                label: 'Nachname',
                required: true,
                width: 12
            },
            {
                name: 'Mail',
                label: 'E-Mail',
                required: true,
                width: 24
            },
            {
                name: 'Geburtsdatum',
                label: 'Geburtsdatum',
                type: 'date',
                width: 12
            },
            {
                name: 'StrHausNr',
                label: 'Anschrift (Straße + Hausnummer)',
                width: 24
            },
            {
                name: 'PLZ',
                label: 'PLZ',
                width: 12
            },
            {
                name: 'Ort',
                label: 'Ort',
                width: 12
            },
            {
                name: 'Telefon',
                label: 'Telefon',
                width: 12
            },
            {
                type: 'title',
                label: 'Intern'
            },
            {
                name: 'Kostenstelle',
                label: 'Kostenstelle',
            },
            {
                name: 'Projekt',
                label: 'Projekt',
            },
            {
                name: 'AE',
                label: 'Aufwandsentschädigung',
                type: 'switch'
            },
            {
                type: 'title',
                label: 'Bankverbindung'
            },
            {
                name: 'Bankname',
                fieldKey: 'Bankverbindung',
                label: 'Bankname',
            },
            {
                name: 'Kontoinhaber',
                fieldKey: 'Bankverbindung',
                label: 'Kontoinhaber',
            },
            {
                name: 'IBAN',
                fieldKey: 'Bankverbindung',
                label: 'IBAN',
            },
            {
                name: 'BIC',
                fieldKey: 'Bankverbindung',
                label: 'BIC',
            },
        ]
    }
})


export default configuration
