import {Button, Card, Col, DatePicker, Divider, Form, Input, message, Modal, Popconfirm, Radio, RadioChangeEvent, Row, Space, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import PageWrapper from '../components/wrapper/page'

import axios from 'axios'
import FormNeuesTeam from "../components/forms/FormNeuesTeam";

import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    CloseCircleOutlined,
    CloseCircleTwoTone,
    DeleteOutlined,
    EditOutlined,
  } from '@ant-design/icons'
import dayjs from 'dayjs'
import Ehrenamt_Detail from './Ehrenamt_Detail'
  const { TabPane } = Tabs

export default () => {

    const history = useHistory()
    const [visible, setVisible] = useState({state: false, url: '', form: ''})
    const [teams, setTeams] = useState([] as any)
    const [times, setTimes] = useState([] as any)
    const [values, setValues] = useState(null as any)
    const [selected, setSelected] = useState([] as any)
    const [page, setPage] = useState('zeiten')
    const [ehrenamt, setEhrenamt] = useState('')

    const getEhrenamt = (filter = 'AE') => {
        axios.get('/ehrenamt/list')
            .then(data => setTeams(data.data.filter((item: any) => {
                if (filter !== 'AE') return !item.AE
                else return item.AE === (filter === 'AE')
            })))
            .catch(error => console.log(error))
    }

    const getZeiten = () => {
        setPage('zeiten')
        axios.get('/buchungen')
            .then(data => setTimes(data.data))
            .catch(error => console.log(error))
    }

    const getData = () => {
        setValues(null)
        setVisible({state: false, url: '', form: ''})
        getEhrenamt()
    }

    useEffect(() => {
        getEhrenamt()
        getZeiten()
    }, [])

    const change = (data, url, form) => {
        setValues(data)
        setVisible({state: true, url, form})
    }

    const deleteItem = (item: any, art) => {
        axios.delete(`/ehrenamt/${item._id}`)
            .then(data => {
                getEhrenamt()
            })
            .catch(error => console.log(error))
    }

    const sendAccount = () => {
        console.log(selected)
        selected.map((item: any) => {
            !item.User && axios.get(`/ehrenamt/account/${item._id}`)
                .then(data => {
                    message.success(`Account wurde erstellt.`)
                })
                .catch(error => {
                    console.log(error)
                })
        })
        getEhrenamt()
    }


    const sendVereinbarungen = () => {
        selected.map((item: any) => {
            axios.post(`/ehrenamt/vereinbarung/pdf`, {ehrenamt: item, month: dayjs(), mail: true}, {responseType: 'blob'})
            .then(data => {
                message.success('Erfolgreich versendet');
            })
            .catch(error => {
                message.error(JSON.stringify(error))
                console.log(error)
            })
        })
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            // console.log(selectedRows.map((item: any) => item._id))

            setSelected(selectedRows)
        }
    }

    const setPageData = (id) => {
        setEhrenamt(id)
        setPage('EA')
    }

    return (
        <>
                <Row  wrap={false} className='h-100'>
                    <Col flex='320px' className='overflow-auto border-right'>
                        <div className='p-3'>

                            <Button block onClick={getZeiten}>Zeiteinträge</Button>
                            <Button 
                                block  
                                className='my-2' 
                                onClick={() => setVisible({state: true, url: '/ehrenamt/neu', form: 'mitarbeiterProfil'})}>
                                    EA hinzufügen
                            </Button>

                            <Radio.Group
                                block
                                options={[
                                    { label: 'EA mit AE', value: 'AE' },
                                    { label: 'EA ohne AE', value: 'ohneAE' }
                                ]}
                                onChange={({ target: { value } }: RadioChangeEvent) => getEhrenamt(value)}
                                defaultValue="AE"
                                optionType="button"
                                buttonStyle="solid"
                            />

                            <Divider />

                            <div>
                                {
                                    teams.map((person: any, i: number) => (
                                        <Card type='inner' size='small' className='my-2' style={{backgroundColor: ehrenamt === person._id ? '#e6f4f1' : ''}} onClick={() => setPageData(person._id)}>
                                            <div className='mb-2 font-weight-bold'>
                                                [{++i}] {person.Vorname} {person.Nachname} 
                                                <Popconfirm
                                                        title="EA löschen?"
                                                        onConfirm={() => deleteItem(person, 'team')}
                                                        okText="Ja"
                                                        cancelText="Nein"
                                                    >
                                                        <Button className='float-right' type={'link'} danger icon={<DeleteOutlined />} size='small' />
                                                </Popconfirm>
                                                <Button 
                                                    ghost 
                                                    type='link' 
                                                    className='float-right'
                                                    onClick={() => change(person, '/ehrenamt/' + person._id, 'mitarbeiterProfil')} 
                                                    icon={<EditOutlined />} 
                                                    size='small' 
                                                />
                                            </div>
                                            <Space>
                                                <Tag color={'blue'}>{person.Kostenstelle}</Tag>

                                                {
                                                    person.AE && <Tag color={'orange'}>EA mit AE</Tag>
                                                }

                                                {
                                                    person.AE && person.signingStatus === 'SIGNED' ? (
                                                        <Tag color={'success'}>Vereinbarung</Tag>
                                                    ) : (
                                                        <Tag color={'magenta'}>Vereinbarung</Tag>
                                                    )
                                                }

                                            </Space>
                                        </Card>
                                    ))
                                }
                            </div>

                        </div>
                    </Col>
                    <Col flex='auto' className='overflow-auto'>
                        <div>
                            
                                {
                                    page === 'zeiten' ? (
                                        <>
                                            <div className='p-3 border-bottom'>
                                                <h3 className='m-0'>Zeiteinträge</h3>
                                            </div>
                                            <div className='p-3'>
                                                <Table pagination={false} dataSource={times} rowKey={'_id'} columns={[
                                                    {
                                                        title: 'Zeitpunkt Eintrag',
                                                        dataIndex: '',
                                                        width: 200,
                                                        render: (d) => <Tag color='blue'>{moment(d.created_at).format('DD.MM.YYYY HH:mm')}</Tag>
                                                    },
                                                    {
                                                        title: 'Zeitraum',
                                                        dataIndex: '',
                                                        width: 200,
                                                        render: (d) => <>
                                                            <div>{moment(d.zeit_von).format('DD.MM.YYYY HH:mm')} -</div>
                                                            <div>{moment(d.zeit_bis).format('DD.MM.YYYY HH:mm')}</div>
                                                        </>
                                                    },
                                                    {
                                                        title: 'Name',
                                                        key: 'name',
                                                        render: (_) => <Button type='link' onClick={() => setPageData(_?.client?._id)}>{_?.client?.Vorname} {_?.client?.Nachname}</Button>
                                                    },
                                                ]} />
                                            </div>
                                        </>
                                    ) : (
                                        <Ehrenamt_Detail id={ehrenamt} />
                                    )
                                }
                            </div>
                    </Col>
                </Row>
            <Modal
                title={`Neues Element hinzufügen`}
                visible={visible.state}
                onCancel={() => {
                    setValues(null)
                    setVisible({state: false, url: '', form: ''})
                }}
                footer={null}
                width={'50vw'}
                destroyOnClose={true}
            >
                <FormNeuesTeam form={visible.form} defaultValues={values} postUrl={visible.url} modalAction={() => getData()} />
            </Modal>
        </>
    )
}
