import React, {Fragment, useEffect, useState} from 'react'
import {Button, Modal, Popconfirm, Space, Table, Tag} from 'antd'
import axios from 'axios'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import FormNeuerAuftrag from '../forms/FormNeuerAuftrag'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'



const TableAuftraege = ({socket}) => {

    const [data, setData] = useState([] as any)
    const [modalData, setModalData] = useState({visible: false, data: {} as any})
    const [visible, setVisible] = useState(false)
    const { pool }: any = useParams()


    useEffect(() => {
        fetchAuftrag()
        socket.on('auftrag', () => fetchAuftrag())
    }, [])

    const fetchAuftrag = () => {
        axios.get('/auftrag/pool/' + pool)
            .then(data => {
                setData(data.data)
            })
            .catch(error => console.log(error))
    }

    const deleteItem = (item: any) => {
        axios.delete(`/auftrag/${pool}/${item._id}`)
            .then(data => {
                console.log(data.data)
            })
            .catch(error => console.log(error))
    }

    const changeItem = (itemData: any) => {

        let data2 = {...modalData.data} as any

        // console.log(itemData)

        data2.gemeldet = itemData

        if (!itemData.beschreibung && modalData.data?.gemeldet.beschreibung.length)
            data2.gemeldet.beschreibung = modalData.data?.gemeldet.beschreibung

        axios.put(`/auftrag/${data2._id}`, data2)
            .then(data => setModalData({visible: false, data: {}}))
            .catch(error => console.log(error))
    }

    const blockItem = (item: any) => {
        axios.put(`/auftrag/${item._id}`, {blocked: !item.blocked})
            .then(data => console.log(data))
            .catch(error => console.log(error))
    }

    const handleCancel = () => setModalData({visible: false, data: {}})

    const columns = [
        {
            title: 'Zeit',
            key: 'time',
            width: '10%',
            render: (item: any) => (
                <span>
                    {moment(item.created_at).format('HH:mm')} / {item?.close ? moment(item?.close).format('HH:mm') : '--'}
                </span>
            )
        },
        {
            title: 'Ort',
            dataIndex: ['gemeldet', 'ort'],
            key: 'ort',
            width: '30%',
            render: (item: any) => (
                <span>
                    {item.name ? `${item.name} / ${item.formatted_address}` : item.formatted_address}
                </span>
            )
        },
        {
            title: 'Beschreibung',
            dataIndex: ['gemeldet', 'beschreibung'],
            key: 'beschreibung',
            width: '30%'
        },
        {
            title: 'Priorität',
            dataIndex: ['gemeldet', 'prio'],
            key: 'prio',
            render: prio => (
                <Tag color={'purple'}>
                    {prio?.label?.toUpperCase()}
                </Tag>
            )
        },
        {
            title: 'Status',
            key: 'tags',
            render: (item: any) => (
                <Space size='middle'>
                    {
                        item.zugewiesen && !item.close &&
                            <Tag color={'blue'}>
                                in Bearbeitung - {item.zugewiesen.name}
                            </Tag>
                    }
                    {
                        item.zugewiesen && item.close &&
                            <Tag color={'blue'}>
                                Abgeschlossen - {item.zugewiesen.name}
                            </Tag>
                    }
                </Space>
            )
        },
        {
            title: 'Aktionen',
            key: 'action',
            render: (item: any) => (
                <Space size='middle'>
                    {
                        !item.zugewiesen &&
                            <Fragment>
                                <Popconfirm
                                    title="Den Auftrag löschen?"
                                    onConfirm={() => deleteItem(item)}
                                    okText="Ja"
                                    cancelText="Nein"
                                >
                                    <Button>Löschen</Button>
                                </Popconfirm>

                                <Button onClick={() => blockItem(item)} icon={!item.blocked ? <UnlockOutlined /> : <LockOutlined />} danger={item.blocked} />

                                <a onClick={() => setModalData({visible: true, data: item})}>Bearbeiten</a>
                            </Fragment>
                    }
                </Space>
            ),
        },
    ]

    return (
        <>
            <Table scroll={{x: true}} loading={data.length} dataSource={data} columns={columns} pagination={false} rowKey={'_id'} />
            <Modal
                title="Auftrag bearbeiten"
                visible={modalData.visible}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <FormNeuerAuftrag socket={socket} defaultValues={modalData.data} modalAction={changeItem} />
            </Modal>
        </>
    )
}

export default TableAuftraege
