import {Alert, Button, Card, Form, Input, Modal, Space, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { io } from 'socket.io-client'

import { useDispatch, useSelector } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartPie, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import FormNeuerAuftrag from '../components/forms/FormNeuerAuftrag'
import Anrufe from '../components/tiles/anrufe'
import TableAuftraege from '../components/tables/auftraege'
import Chatbox from '../components/tables/chatbox'
import {GoogleMap, InfoBox, Marker} from "@react-google-maps/api";
import FormNeuesTeam from '../components/forms/FormNeuesTeam'

const { TabPane } = Tabs

const containerStyle = {
    width: '100%',
    height: '80vh'
}

const center = {
    lat: 52.5224962,
    lng: 13.4441354
}

const socket = io(process.env.NODE_ENV === 'production' ? 'https://kbcc.app.berliner-stadtmission.de' : 'http://localhost:8080')

export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([] as any)
    const [dataSource, setDataSource] = useState(null as any)
    const [defaultMessage, setDefaultMessage] = useState([] as any)
    const user = useSelector(state => state.user)
    const [modal, setModal] = useState(false)
    const [busse, setBusse] = useState([] as any)
    const [infos, setInfos] = useState([] as any)
    const { pool }: any = useParams()

    useEffect(() => {
        socket.emit('room', pool, 'CC')
        socket.on('auftrag', () => fetchInfos())
        // console.log(socket.id, user)
    }, [])

    useEffect(() => {
        fetchKB()
        fetchInfos()
        socket.on('kbuser', (users) => {
            fetchKB()
        })
    }, [])

    const fetchKB = () => {
        axios.get('/pool/schichten/' + pool)
            .then(data => {
                // console.log(data.data)
                setDataSource(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchInfos = () => {
        axios.get('/infos/' + pool)
            .then(data => {
                // console.log(data.data)
                setInfos(data.data)
            })
            .catch(error => console.log(error))
    }

    const remInfo = (id) => {
        axios.delete(`/infos/${pool}/${id}`)
            .then()
            .catch(error => {
                console.log(error)
            })
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'team',
            render: (text) => `${text?.name.toUpperCase()}`
        },
        {
            title: 'Telefon',
            key: 'phone',
            dataIndex: 'team',
            render: (text) => `${text?.telefon}`
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text, record) => <Tag color={'geekblue'}>
                {text.status?.label}
            </Tag>
        },
    ]

    return (
        <PageWrapper title={`${moment(pool).format('dddd, Do MMMM YYYY')}`}>
            <div className={'row'}>
                <div className={'col-md-8 mb-4'}>
                    <Card title="Auftrag erfassen" extra={<a href="#">Zur Übersicht</a>}>
                        <FormNeuerAuftrag socket={socket} />
                    </Card>
                </div>
                <div className={'col-md-4'}>
                    <Card title="Anruf erfassen">
                        <Anrufe pool={pool} socket={socket} />
                    </Card>
                    <Card title="Fahrzeuge" className={'mt-4'}>
                        <Table scroll={{x: true}} dataSource={dataSource} columns={columns} pagination={false} showHeader={false} />
                    </Card>
                </div>
            </div>
            <div className={'row mt-4'}>
                <div className={'col'}>
                    <Card title="Aufträge" extra={
                        <Space>
                            <Button onClick={() => history.push(`/auftrag/pool/${pool}/karte`)} type={'link'}>Zur Kartenansicht</Button>
                            <Button onClick={() => setModal(true)}>Neue Tagesinfo</Button>
                        </Space>
                    }>
                        {
                            infos.map((item: any) => (
                                <Alert
                                    className='mb-3'
                                    message={`INFO | ${moment(item.created_at).format('HH:mm')} Uhr`}
                                    description={item.beschreibung}
                                    type="info"
                                    action={
                                        <Button onClick={() => remInfo(item._id)} size="small" danger>
                                            Löschen
                                        </Button>
                                    }
                                />
                            ))
                        }


                        <TableAuftraege socket={socket} />
                    </Card>
                </div>
            </div>
            

            <Modal
                title="Tagesinfo"
                visible={modal}
                onCancel={() => setModal(false)}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <FormNeuesTeam form={'tagesinfo'} postUrl={`/infos/${pool}`} modalAction={() => setModal(false)} />
            </Modal>

        </PageWrapper>
    )
}
