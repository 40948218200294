import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import Home from '../pages/Home'
import Auftraege from '../pages/Auftraege'
import Einstellungen from '../pages/Einstellungen'

import Header from '../components/header/header'
import PageStatistik from '../pages/Statistik'
import Auftraege2023 from "../pages/Auftraege2023";
import Ehrenamt from '../pages/Ehrenamt'
import Ehrenamt_Detail from '../pages/Ehrenamt_Detail'


export const AppRouter = () => {
    const user = useSelector(state => state.user)

    return (
        <Router>
            <Header />
            <div className='overflow-auto h-100'>
                <Route exact path="/" explicit component={() => <Home />} />
                <Route exact path="/auftrag/pool" explicit component={() => <Home />} />
                <Route exact path="/auftrag/pool/:pool" explicit component={() => <Auftraege />} />
                <Route exact path="/auftrag/pool/:pool/karte" explicit component={() => <Auftraege2023 />} />
                <Route path="/statistik/auswertung" component={() => <PageStatistik />} />


                {
                    user.user.realm_access.roles.includes('BSM') && (
                        <>
                            <Route exact path="/einstellungen" explicit component={() => <Einstellungen />} />
                            <Route exact path="/ehrenamt" explicit component={() => <Ehrenamt />} />
                            
                        </>
                    )
                }

            </div>
        </Router>
    )
}
